import React, { useState } from "react";
import "./taskItem.css";
import { DownloadButton } from "../buttons/DownloadButton";
import { UploadButton } from "../buttons/UploadButton";
import { images } from "../../../constants/ImageImports";
import { InputText } from "../formInputs/InputText";
import { UploadInput } from "../formInputs/UploadInput";
import { TaskStepper } from "./TaskStepper";
import { getToast } from "../../../utils";
import { postApi } from "../../../api/api.client";
import { APIS } from "../../../api/api.constant";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoader } from "../../../redux/slices/loaderSlice";
import { TOAST_MESSAGE } from "../../../constants/common";
import { toast } from "react-toastify";

const CheckIcon = (isActive) => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0.826172C6.28009 0.826172 0 7.10627 0 14.8262C0 22.5461 6.28009 28.8262 14 28.8262C21.7199 28.8262 28 22.5461 28 14.8262C28 7.10627 21.7199 0.826172 14 0.826172ZM21.0957 11.8594L13.5123 19.4426C13.2848 19.6701 12.9861 19.7846 12.6875 19.7846C12.3889 19.7846 12.0902 19.6701 11.8627 19.4426L8.07111 15.651C7.6148 15.1949 7.6148 14.4575 8.07111 14.0014C8.5272 13.5451 9.26439 13.5451 9.7207 14.0014L12.6875 16.9682L19.4461 10.2098C19.9022 9.75347 20.6394 9.75347 21.0957 10.2098C21.5518 10.6659 21.5518 11.4031 21.0957 11.8594Z"
        fill={isActive?.isActive ? "#197BBD" : "#9E9E9E"}
      />
    </svg>
  );
};

const isDateGreaterThanToday = (dateString) => {
  const today = new Date();
  const date = new Date(dateString);
  if (date > today) {
    return false;
  } else {
    return true;
  }
};

// TaskItems component
export const TaskItems = ({
  item,
  isAssignment = false,
  allData,
  setRefresh,
}) => {
  const [show, setShow] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [stepper, setStepper] = useState(false);

  // TaskTop component
  const TaskTop = ({
    item,
    show,
    setShow,
    setStepper,
    isAssignment,
    stepper,
    allData,
  }) => {
    return (
      <div
        className="task_item_top"
        onClick={() => {
          if (isDateGreaterThanToday(allData?.task_start_date)) {
            if (
              !!item?.taskStatus?.is_submited === false &&
              !!item?.taskStatus?.is_done === false &&
              !!item?.taskStatus?.is_approved === false
            ) {
              setShow(!show);
            }
            if (
              !!item?.taskStatus?.is_rework === true &&
              !!item?.taskStatus?.is_submited === true &&
              !!item?.taskStatus?.is_done === false &&
              !!item?.taskStatus?.is_approved === false
            ) {
              setShow(!show);
              setStepper(!stepper);
            }
            if (!!item?.taskStatus?.is_submited === true) {
              setStepper(!stepper);
            }
            if (isAssignment && item?.taskStatus === true) {
              setStepper(!stepper);
            }
            if (
              isAssignment &&
              !!allData?.studentAssignment?.is_submited === true &&
              !!allData?.studentAssignment?.is_done === true &&
              !!allData?.studentAssignment?.is_approved === true
            ) {
              setStepper(!stepper);
            }
            if (
              isAssignment &&
              !!allData?.studentAssignment?.is_submited === true
            ) {
              setStepper(!stepper);
            }
          }
        }}
      >
        <div className="task_item_Icon cursor_pointer">
          <div>
            {isAssignment ? (
              <img src={images?.pdfIcon} alt="img" />
            ) : (
              <CheckIcon isActive={!!item?.taskStatus?.is_submited} />
            )}
          </div>
        </div>
        <h5 className="cursor_pointer">
          {isAssignment ? item.assessment_title : item.task_title}
        </h5>

        {!item?.taskStatus?.is_done && isAssignment ? (
          <div className="task_item_btns">
            {item?.assessment_document &&
              isDateGreaterThanToday(allData?.task_start_date) && (
                <DownloadButton filePath={item?.assessment_document} />
              )}
            {isDateGreaterThanToday(allData?.task_start_date) && (
              <UploadButton actionShow={setShow} showValue={show} />
            )}
          </div>
        ) : item?.taskStatus?.is_done ? (
          <div className="task_item_btns">
            <button
              className="btn_reset download_btn"
              style={{ backgroundColor: "#197BBD" }}
            >
              Completed
            </button>
          </div>
        ) : item?.taskStatus?.is_approved ? (
          <div className="task_item_btns">
            <button className="btn_reset download_btn green_btn">
              Approved
            </button>
          </div>
        ) : item?.taskStatus?.is_rework ? (
          <div className="task_item_btns">
            <button
              className="btn_reset download_btn"
              style={{ backgroundColor: "#E9A73E" }}
            >
              Re-work
            </button>
          </div>
        ) : (
          item?.taskStatus?.is_submited && (
            <div className="task_item_btns">
              <button
                className="btn_reset download_btn"
                style={{ background: "#197BBD" }}
              >
                Submitted
              </button>
            </div>
          )
        )}
      </div>
    );
  };

  const ThemeBtn = ({ onClick }) => {
    return (
      <button className="btn_reset theme_btn" onClick={onClick}>
        Done
      </button>
    );
  };

  // TaskBottom component
  const TaskBottom = ({
    isAssignment,
    show,
    allData,
    item,
    setRefresh,
    setShow,
  }) => {
    const dispatch = useDispatch();
    const studentData = useSelector((state) => state?.authSlice);
    const [file, setFile] = useState(null);
    const [text, setText] = useState("");
    const onTextChange = (e) => {
      setText(e.target.value);
    };
    const onChange = (e) => {
      const getFile = e.target.files[0];
      if (getFile.type === "application/pdf") {
        setFile(getFile);
      } else {
        toast.error("Only updload Pdf");
      }
    };

    const onSubmit = () => {
      if (text === "") {
        return getToast("error", TOAST_MESSAGE.EMPTY_MESSAGE);
      }
      if (isAssignment && file === null) {
        return getToast("error", TOAST_MESSAGE.NO_FILE);
      }
      if (isAssignment && file !== null) {
        dispatch(toggleLoader({ loader: true }));
        const formData = new FormData();
        formData.append("assignment_id", allData?.assignment?._id);
        formData.append("todoFile", file);
        formData.append("submited_text", text);

        postApi(APIS.UPDATE_ASSIGNMENT, Object.fromEntries(formData), true)
          .then((res) => {
            setRefresh((s) => !s);
            getToast("success", TOAST_MESSAGE.SUCCESS);
          })
          .catch((err) => {})
          .finally(() => {
            dispatch(toggleLoader({ loader: false }));
            setShow(!show);
          });
      } else {
        let payload = {
          task_id: item?._id,
          student_id: studentData?.userId,
          submited_text: text,
        };
        dispatch(toggleLoader({ loader: true }));
        postApi(APIS.UPDATE_TASK, {
          ...payload,
        })
          .then((res) => {
            getToast("success", TOAST_MESSAGE.SUCCESS);
            setRefresh((s) => !s);
          })
          .catch((err) => {})
          .finally(() => {
            dispatch(toggleLoader({ loader: false }));
            setShow(!show);
          });
      }
    };

    return (
      <>
        {isAssignment &&
          show &&
          (allData?.studentAssignment?.is_submited === false ||
            allData?.studentAssignment?.is_submited === undefined) && (
            <div>
              <div
                style={{
                  marginTop: "10px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                  marginBottom: "-10px;",
                }}
              >
                {file?.name ? file?.name : ""}
              </div>
              <div className={`task_item_bottom show`}>
                <UploadInput onChange={onChange} />
              </div>
            </div>
          )}
        {isAssignment &&
          show &&
          allData?.studentAssignment?.is_submited === true &&
          allData?.studentAssignment?.is_rework === true &&
          (allData?.studentAssignment?.is_approved === false ||
            allData?.studentAssignment?.is_approved === null) && (
            <div>
              <div
                style={{
                  marginTop: "10px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                  marginBottom: "-10px",
                }}
              >
                {file?.name ? file?.name : ""}
              </div>
              <div className={`task_item_bottom show`}>
                <UploadInput onChange={onChange} />
              </div>
            </div>
          )}
        {isAssignment &&
          show &&
          (allData?.studentAssignment?.is_submited === false ||
            allData?.studentAssignment?.is_submited === undefined) && (
            <div className={`task_item_bottom show`}>
              <InputText onTextChange={onTextChange} />
              <ThemeBtn onClick={onSubmit} />
            </div>
          )}
        {isAssignment &&
          show &&
          allData?.studentAssignment?.is_submited === true &&
          allData?.studentAssignment?.is_rework === true &&
          (allData?.studentAssignment?.is_approved === false ||
            allData?.studentAssignment?.is_approved === null) && (
            <div className={`task_item_bottom show`}>
              <InputText onTextChange={onTextChange} />
              <ThemeBtn onClick={onSubmit} />
            </div>
          )}
        {!isAssignment && show && (
          <div className={`task_item_bottom show`}>
            <InputText onTextChange={onTextChange} />
            <ThemeBtn onClick={onSubmit} />
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={`task_item ${show ? "show" : ""}`}
      style={{
        background:
          !isDateGreaterThanToday(allData?.task_start_date) && "lightgray",
      }}
    >
      <TaskTop
        isAssignment={isAssignment}
        item={item}
        show={show}
        setShow={setShow}
        setStepper={setStepper}
        stepper={stepper}
        allData={allData}
      />
      <TaskBottom
        setRefresh={setRefresh}
        isAssignment={isAssignment}
        show={show}
        allData={allData}
        item={item}
        setShow={setShow}
      />

      {stepper && (
        <TaskStepper
          itemData={
            isAssignment
              ? { ...item, taskStatus: allData?.studentAssignment }
              : item
          }
          isAssignment={isAssignment}
        />
      )}
    </div>
  );
};
