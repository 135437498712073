// export const BASE_URL = "http://3.111.233.200:8081"; //Development URL
// export const BASE_URL = "http://192.168.1.12:8081"; // Local network URL
export const BASE_URL = process.env.REACT_APP_RISE_STUDENT_PRODUCTION_BASE_URL; //Production URL

export const MEDIA_URL = "http://3.111.233.200:8080";

export const API_PREFIX = "/api/v1/student";
export const PERSIST_STORAGE = "persist:root";

export const APIS = {
  LOGIN: "/login",
  GET_USER_DETAILS: "/profile",
  UPDATE_USER: "/profile/update",
  CHANGE_PASSWORD: "/change/password",
  CONTACT_US: "/get/contact-us",
  TERMS_CONDITIONS: "/terms/conditions",
  PRIVACY: "/privacy/policy",
  GET_TO_LIST: "/to-do/list",
  TO_DI_LIST: "/to-do/list",
  FORGOT_PASSWORD: "/forgot/password",
  RESET_PASSWORD: "/save/password",
  CURRENT_TASK: "/dashboard/current/task",
  UPDATE_TASK: "/review/task",
  UPDATE_ASSIGNMENT: "/update/assignment",
  POST_CLOUD_TOKEN: "/update/fmc",
  GET_NOTIFICATION: "/notification/list",
  CHECK_LINK:"/check-link",
  SET_PASSWORD: "/reset-password",
};
